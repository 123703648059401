import React from 'react'

import { normalizeBlockContentNodes } from '@blocks-helpers'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Header from '@solid-ui-blocks/Header/Block01'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import Pricing from '@solid-ui-blocks/Pricing/Block01'
import FeatureThree from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import Screenshot from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import Process from '@solid-ui-blocks/Features/Block03'
import Tabs from '@solid-ui-components/Tabs'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'

import styles from './_styles'
import theme from './_theme'

const LD_JSON = {
  "@context": "http://schema.org",
  "@type": "WebApplication",
  "name": "Bisttros",
  "url": "https://cloud.bisttros.com",
  "description": "Controla y administra tu negocio de manera eficiente con nuestro Software Punto de Venta (POS) en la nube. Perfecto para restaurantes, bares, cafeterías y franquicias. Además, ¡tenemos un plan gratis para siempre! Regístrate ahora.",
  "applicationCategory": "BusinessApplication",
  "about": {
    "@type": "Thing",
    "description": "pos, software punto de venta, software para restaurante"
  },
  "browserRequirements": "Requires JavaScript. Requires HTML5.",
  "softwareVersion": "1.0.0",
  "softwareHelp": {
    "@type": "CreativeWork",
    "url": "https://bisttros.com/faq/"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "reviewCount": "250"
  },
  "offers": {
    "@type": "Service",
    "lowPrice": "0"
  },
  "operatingSystem": "Web"
}

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Sistema POS para tu restaurante + Menú Digital' canonical={''} ld_json={LD_JSON} description={'Controla y administra tu negocio con nuestro Software Punto de Venta (POS) en la nube. Perfecto para restaurantes, cafeterías y franquicias, tenemos un plan gratis!'}/>

      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />

      <Header content={content['header-light']} menuJustify='space-between' />
      <Divider space='5' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>

      <Divider space='5' />
      <Divider space='5' />

      <Container variant='wide' sx={styles.tabsContainer}>
        <Tabs space={5}>
          <FeatureOne content={content['tab-feature-pos']} />
          <FeatureThree content={content['tab-feature-analysis']} />
          <FeatureOne content={content['tab-feature-other-services']} />
        </Tabs>
      </Container>

      <Divider space='5' />
      <Divider space='5' />
      <Container variant='wide' sx={styles.tabsContainer}>
        <Tabs space={3} variant='dots' position='bottom' arrows>
          <Screenshot content={content['screenshot-one']} />
          <Screenshot content={content['screenshot-two']} />
          <Screenshot content={content['screenshot-three']} />
        </Tabs>
      </Container>

      <Divider space='5' />

      <Process content={content['process']} />
      <Divider space='5' />
      <Pricing content={content['pricing']} />

      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query indexpageSiteBlockContent {
    allBlockContent(filter: { page: { in: ["index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
